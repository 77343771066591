<template>
  <div>
    <water-nav selected="4"></water-nav>
    <div class="ly-center">
      <div class="time-box">
        <div class="time-it">开始日期<input class="time-pick" @click="openPicker('start')" type="text"
                                        v-model="hisForm.start">
        </div>
        <div class="time-it">结束日期 <input class="time-pick" @click="openPicker('end')" type="text"
                                         v-model="hisForm.end">
        </div>
      </div>
      <div class="quick-box">
        <div class="quick-item" @click="handleQuickData(1)">近1天</div>
        <div class="quick-item" @click="handleQuickData(3)">近3天</div>
        <div class="quick-item" @click="handleQuickData(7)">近7天</div>
        <div class="quick-item" @click="handleQuickData(30)">近30天</div>
        <div class="quick-item" @click="handleQuickData(90)">近90天</div>
      </div>
      <div class="tips">勾选变量 <span>最多可选5个</span> <em>已选{{ this.hisForm.variableId.length || 0 }}个</em>
      </div>
      <div class="sh-input">
        <div class="tab-type">
          <div @click="shTypechange('zhName')" :class="[shType==='zhName'?'on':'','sh-type']">中文名</div>
          <div @click="shTypechange('name')" :class="[shType==='name'?'on':'','sh-type']">变量名</div>
        </div>
        <input v-if="shType=='name'" type="text" placeholder="搜索变量名称" v-model="variableName">
        <input v-if="shType=='zhName'" type="text" placeholder="搜索变量中文名" v-model="zhName">
        <div class="btn" @click="loadBase">搜索</div>
      </div>
      <div class="data-list">
        <div class="data-line" v-for="item in variableData" :key="item.id">
          <input type="checkbox" @change="checkChange" class="ly-checkbox" v-model="hisForm.variableId"
                 :value="item.variableId"/>
          <div class="zh-name">{{ item.zhName }}</div>
          <div class="name">{{ item.variableName }}</div>
        </div>
      </div>
    </div>
    <div class="ly-wrap">
      <div class="choose-btn">
        <div class="ly-btn-center">
          <div class="btn" @click="handleGo('/water/table')">表格</div>
          <div class="btn" @click="handleGo('/water/chart','1')">趋势图</div>
        </div>
      </div>
    </div>
    <mt-datetime-picker
        ref="picker"
        type="datetime"
        :startDate="start"
        :endDate="end"
        @confirm="handleConfirm"
    >
    </mt-datetime-picker>

  </div>
</template>

<script>

import {getAction,} from '../../api/manage'
import { Toast} from 'mint-ui';
import moment from 'moment'
import storage from '../../store/storage.js'
import {getSelectWater} from "../../utils/auth";
import WaterNav from "./modules/WaterNav";

export default {
  components: {WaterNav},
  computed: {},
  data() {
    return {
      shType: 'zhName',
      zhName: null,
      variableName: null,
      query: {
        pageNo: 1,
        pageSize: 10,
      },
      hisForm: {
        variableId: [],
        start: moment().locale('zh-cn').subtract(2, 'hours').format('YYYY-MM-DD HH:mm:ss'),
        end: moment().locale('zh-cn').format('YYYY-MM-DD HH:mm:ss'),
      },
      checkValue: [],
      start: moment().add(-3, 'month').toDate(),
      end: moment().toDate(),
      variableData: [],
      pickType: null,// 时间选择类型
      checked: false
    }
  },
  mounted() {
  },
  created() {
    this.loadBase()
  },
  methods: {
    async loadBase() {
      const selectWater = await getSelectWater()
      // 根据水厂ID 中文名称 获取变量列表
      const body = {
        waterId: selectWater.id,
        zhName: this.zhName,
        variableName: this.variableName
      }
      getAction('/water/variable/queryListByWaterId', body).then(res => {
        this.variableData = res.data.result
      })
    },
    shTypechange(type) {
      if (type == 'name') {
        this.shType = "name"
        this.zhName = null
      } else {
        this.shType = 'zhName'
        this.variableName = null
      }
      this.loadBase()
    },
    handleGo(path, month) {
      // 判断选择点位数量
      if (this.hisForm.variableId.length < 1) {
        Toast('请先选择需要查看的变量！');
        return
      }

      if (month) {
        let start = new Date(this.hisForm.start);
        let end = new Date(this.hisForm.end);
        let num = Math.abs((end - start) / (1000 * 60 * 60 * 24))
        if (num > 7) {
          Toast('趋势图手机仅提供一个周数据，请重新选择日期！');
          return
        }
      }

      storage.put('condition', this.hisForm)
      this.$router.push({
        path: path,
      })
    },
    checkChange() {
      if (this.hisForm.variableId.length > 5) {
        Toast('最多可选五个变量！');
        this.hisForm.variableId.splice(4, 1);
        // this.checked = true
      }
    },
    openPicker(type) {
      this.pickType = type
      this.$refs.picker.open();
    },
    handleQuickData(day) {
      this.hisForm.start = moment().locale('zh-cn').subtract(day, 'days').format('YYYY-MM-DD HH:mm:ss')
      this.hisForm.end = moment().locale('zh-cn').format('YYYY-MM-DD HH:mm:ss')
    },
    handleConfirm(data) {
      if (this.pickType == 'end') {
        this.hisForm.end = moment(data).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.hisForm.start = moment(data).format('YYYY-MM-DD HH:mm:ss')
      }
    },
  },
  destroyed: function () {
  },
}
</script>

<style scoped lang="less">
.time-box {
  width: 100%;
  height: 2.4rem;
  padding-top: 0.1rem;

  .time-it {
    width: 100%;
    display: flex;
    line-height: 0.88rem;
    font-size: 0.3rem;
    font-weight: 400;
    color: #333333;
    margin-top: 0.2rem;


    .time-pick {
      margin-left: 0.2rem;
      flex: 1;
      border: 1px solid #0088FFFF;
      box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.18);
      border-radius: 10px;
      outline: none;
      background: url("../../assets/img/i-time.png") no-repeat 0.36rem center;
      background-size: 0.28rem;
      padding-right: 0.2rem;
      text-indent: 0.9rem;
    }
  }
}

.quick-box {
  display: flex;
  padding-bottom: 0.15rem;

  .quick-item {
    height: 0.5rem;
    line-height: 0.5rem;
    font-size: 0.24rem;
    margin: 0 0.2rem;
    border: 1px solid #26a2ff;
    background-color: transparent;
    color: #26a2ff;
    border-radius: 0.1rem;
    padding: 0 0.12rem;
    cursor: pointer;
  }
}

.tips {
  font-size: 0.3rem;
  font-weight: 400;
  color: #333333;
  height: 0.6rem;

  span {
    font-size: 0.22rem;
    color: #999999FF;
  }

  em {
    margin-left: 0.2rem;
    font-size: 0.22rem;
    font-style: normal;
    color: #00BF00FF;
  }
}

.sh-input {
  width: 100%;
  height: 1rem;
  display: flex;

  .tab-type {
    width: 1.8rem;
    height: 0.88rem;
    display: flex;
    border-radius: 10px;
    background: #0088FF;

    .sh-type {
      flex: 1;
      line-height: 0.88rem;
      text-align: center;
      box-shadow: 0 4px 4px 0 rgba(0, 136, 255, 0.28);
      color: #333333;
      background-color: #FFFFFF;
    }

    .on {
      color: #FFFFFF;
      background: #0088FF;
      font-size: 0.2rem;
    }
  }

  input {
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    flex: 1;
    line-height: 0.88rem;
    height: 0.88rem;
    text-indent: 1.1rem;
    background: url("~@/assets/img/search.png") no-repeat 0.4rem center;
    background-size: 0.44rem;
    outline: none;
    border: 1px solid #999999;
    border-radius: 10px;
    box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.18);
  }

  .btn {
    width: 1.28rem;
    text-align: center;
    line-height: 0.88rem;
    height: 0.88rem;
    background: #0088FF;
    box-shadow: 0px 4px 4px 0px rgba(0, 136, 255, 0.28);
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 0.28rem;
  }
}

.data-list {
  width: 100%;
  height: auto;
  //overflow-y: scroll;
  margin-top: 0.2rem;
  border-radius: 0.1rem;
  padding-bottom: 1.5rem;

  .data-line {
    height: 0.88rem;
    line-height: 0.88rem;
    background-color: #EDF6FFFF;
    border-top: 1px solid #A6E1FFFF;
    display: flex;
    font-weight: 400;


    input {
      margin-left: 0.4rem;
      margin-top: 0.3rem;
    }

    .ly-checkbox {
      width: 0.44rem;
      height: 0.44rem;
      background: #FFFFFF;
      border: 1px solid #999999;
      border-radius: 50%;
    }

    .name {
      font-size: 0.32rem;
      color: #0088FF;
      flex: 1;
      text-indent: 0.2rem;
    }

    .zh-name {
      font-size: 0.24rem;
      color: #333333;
      text-align: left;
      width: 2.5rem;
      margin-right: 0.2rem;
    }
  }

  .data-line:first-child {
    border-top: none;
  }
}

.choose-btn {
  width: 7.5rem;
  height: 1.27rem;
  position: fixed;
  bottom: 0;
  background-color: #F2F2F2FF;

  .ly-btn-center {
    width: 6.55rem;
    display: flex;
    text-align: center;
    margin: 0.2rem auto 0;

    .btn {
      width: 3.15rem;
      height: 0.88rem;
      color: #FFFFFF;
      font-size: 0.28rem;
      line-height: 0.88rem;
      background: #0088FF;
      box-shadow: 0px 4px 4px 0px rgba(0, 136, 255, 0.28);
      border-radius: 10px;
    }

    .btn:last-child {
      margin-left: 0.24rem;
    }
  }
}
</style>

